<template>
    <div class="v-header-contain">
      <div class="vlt-left">
        <div class="vlt-header-left">
          <div class="hamb-btn">
            <button
              type="button"
              class="btn-hamb lite"
              @click="() => toggleMenuCB(true)"
              aria-label="menu-hamb-btn"
              aria-labelledby="menu"
              role="none"
            >
              <img src="@/assets/icons/hamburger-ico.svg" alt="hamburger icon" loading="lazy" />
            </button>
            </div>
             <transition name="popup-animate">
            <Header-menu
              v-show="showMenu"
              :size="`260px`"
              class="main-menu"
              :closePopup="toggleMenuCB"
            />
             </transition>
          
          <div class="vlt-header-logo">
            <router-link :to="{ name: 'Home' }" aria-label="Home Link">
              <img class="logo" :src="vendorLogo" alt="logo" id="logo" loading="lazy" />
            </router-link>
          </div>
        </div>
      </div>
      <div class="vlt-right">
        <header-right class="vlt-header-right" />
      </div>
    </div>
 
</template>

<script>
import { EventBus } from "@/main";
import { mapGetters } from "vuex";

export default {
  name: "Header",
  data() {
    return {
      showMenu: false,
      vendorLogo: ""
    };
  },
  computed: {
    ...mapGetters(["appConfig","vendorAssets"])
  },
  created() {
    this.vendorLogo = require(`@/assets/icons/logo/${this.vendorAssets}/logo.svg`);
    EventBus.$on('close-menu',this.toggleMenuCB);
  },
  beforeDestroy(){
    EventBus.$off('close-menu',this.toggleMenuCB);
  },
  methods: {
    toggleMenuCB(state=false) {
      EventBus.$emit('dropdown-switch',{menu:state});
      this.showMenu = state;
    }
  },
  components: {
    "header-right": () =>
      import(
        /* webpackChunkName: "headerRight" */ "@/components/Header/right/headerRight.vue"
      ),
    "Header-menu": () =>
      import(
        /* webpackChunkName: "HeaderMenu" */ "@/components/Header/menu/menu.vue"
      )
  }
};
</script>

<style lang="scss">
@import "~sass/modules/components";
@import "~sass/modules/_mediaQueries.scss";
@import "~sass/modules/_hovereffects.scss";

.v-header-contain {
  padding: 0px;
  height:100%;
  margin: 0px auto;
  display:flex;
  max-width:var(--max-width);
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
 
  .vlt-left,.vlt-right{
    height:100%;
  }
  .vlt-header-left {  
    display: flex;
    flex-flow:row nowrap;
    align-items: center;
    height: 100%;
    position: relative;
      .main-menu {
          position: absolute;
          top: $header-total-height + $dropdown-gap;
          left: 0;
        }
  }
  .vlt-header-right {
    position: relative;
    padding: 0px;
    height:100%;
  }
}

.hamb-btn {
  position: relative;
  .btn-hamb{
      display: block;
      img {
        display: block;  
        width: 22px;
        height: $header-icons-height;
        cursor: pointer;
        @include headerIconsHover;
      }
  }

}
.vlt-header-notif,.vlt-header-prof{
  img{
    @include headerIconsHover;
  }
}
.vlt-header-logo {
  display: block;
  padding: 0 20px;
  cursor: pointer;
  a{
    display: block;
  }
  img {
    width: 109px;
    height: 27px;
    display: block;
  }
}
#only_kannada .vlt-header-logo img{
  height:50px;
  width: 50px;
}

//(max-width:480px)



// @media only screen and (max-width: 600px) {
@include breakpoint(max600) {
  .vlt-header-logo {
    padding: 0px 8px;
  }
  // .vlt-header-logo img {
  //   width: 80px;
  //   height: 27px;
  // }
}
//@media only screen and (max-width:480)
// @include breakpoint(max480){
//   .vlt-header-logo img {
//     width: 60px;
//     height: 27px;
//   }
// }
// @media only screen and (min-width: 600px) {
@include breakpoint(min600) {
  .v-header-contain {
    .vlt-header-logo {
      padding: 0 40px;
    }
    .vlt-header-right {
      margin-right: 5%;
    }
  }
}



// @media screen and (min-width: 1900px) {
@include breakpoint(min1824) {
  .vlt-header-logo {
    img {
      // width: 20%;
      width: 4vw;
    }
  }
  .v-header-contain {
    .vlt-header-right {
      margin-top: 0.5%;
    }
  }
}
</style>